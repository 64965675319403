import React from 'react';

import styles from './loader.module.scss';

export const Loader = () => (
  <svg
    className={styles.loader}
    viewBox="-10 -12 32 32"
    xmlns="http://www.w3.org/2000/svg">
    <svg
      id="enBXAMuiL9K1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32">
      <ellipse rx="4.2" ry="4.2" transform="translate(6 6)" fill="#a6a1c5" />
      <g id="enBXAMuiL9K3_to" transform="translate(7.6,16)">
        <ellipse rx="4.2" ry="4.2" fill="#181926" />
        <animateTransform
          id="first"
          attributeName="transform"
          attributeType="XML"
          type="translate"
          begin="0s;second.end"
          dur="1.5s"
          from="2 6"
          to="10 6"
        />
        <animateTransform
          id="second"
          attributeName="transform"
          attributeType="XML"
          type="translate"
          begin="first.end"
          dur="1.5s"
          from="10 6"
          to="2 6"
        />
      </g>
    </svg>
  </svg>
);
