import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { ComponentType, useEffect, useState } from 'react';

import { useAbortableEffect } from 'src/utils/hooks/use-abortable';

import PageLoading from '../../common/page-loading/page-loading';
import { useStores } from '../../common/root-store-provider/root-store-provider';

const page = <T extends object>(Component: ComponentType<T>) =>
  observer((props: T) => {
    const [mounted, setMounted] = useState(false);
    const [isTestsLoaded, setIsTestsLoaded] = useState(true);
    const {
      authStore: { variant, setVariant, fetchUser, user, auth_token },
      analyticsStore: { segment, getAbTests },
    } = useStores();
    const router = useRouter();
    const { query, isReady } = router;

    useEffect(() => {
      setMounted(true);
    }, []);
    useAbortableEffect(() => {
      // noinspection JSIgnoredPromiseFromCall
      fetchUser({ token: auth_token });
    }, [fetchUser]);

    const variantFromQuery = query['variant'];

    useEffect(() => {
      //Set variant from query and replace variant if query with variant changed
      if (
        variantFromQuery &&
        variant !== variantFromQuery &&
        typeof variantFromQuery === 'string'
      ) {
        setVariant(variantFromQuery);
      } else if (
        // Set variant to undefined if reset is true and user have active variant in store
        variantFromQuery === undefined &&
        variant !== undefined &&
        !!query['reset']
      ) {
        setVariant(undefined);
      }
    }, [query, setVariant, variantFromQuery, variant]);

    useAbortableEffect(
      (abortSignal) => {
        if (!segment && query['reset'] === undefined && isReady) {
          setIsTestsLoaded(false);
          getAbTests({
            variant: variant ?? '',
            utm_source: (query['utm_source'] as string) ?? '',
            utm_campaign: (query['utm_campaign'] as string) ?? '',
            region: (query['region'] as string) ?? '',
            abortSignal,
          }).then(() => setIsTestsLoaded(true));
        }
      },
      [getAbTests, isReady, query, segment, variant],
    );

    // The check on `mounted` helps avoid hydration DOM mismatch
    // that happens because `user` is always empty during SSR,
    // but may be `null` if the user is not logged in
    return !mounted || user === undefined || !isTestsLoaded ? (
      <PageLoading />
    ) : (
      <Component {...props} />
    );
  });

export default page;
