import React from 'react';

import { Loader } from 'src/components/common/loader/loader';

const PageLoading = () => (
  <div
    style={{
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#181926',
    }}>
    <Loader />
  </div>
);

export default PageLoading;
