import { DependencyList, useCallback, useEffect, useRef } from 'react';

export const useAbortableEffect = (
  effect: (abortSignal: AbortSignal) => void | (() => void),
  deps?: DependencyList,
): void => {
  useEffect(
    () => {
      const abortController = new AbortController();
      const cleanup = effect(abortController.signal);
      return () => {
        abortController.abort();
        cleanup?.();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
};

export const useAbortableCallback = <A extends any[], R>(
  callback: (abortSignal: AbortSignal, ...args: A) => R,
  deps: DependencyList,
): ((...args: A) => R) => {
  const abortController = useRef<AbortController | undefined>();
  const abort = () => {
    abortController.current?.abort();
    abortController.current = undefined;
  };

  useEffect(
    () => abort,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );

  return useCallback(
    (...args) => {
      abort();
      abortController.current = new AbortController();
      return callback(abortController.current.signal, ...args);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
};
